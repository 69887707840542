import * as React from "react";
import { useTranslation } from "react-i18next";
import { Day, TClass } from "../../../../util/enpoints/types";
import TimetableClass from "./class";
import { tw } from "../../../../util/tailwind-classnames";

type Props = {
  day: Day;
  preview: boolean;
  data: TClass[];
  selectedClasses: Set<string>;
  select: (uuid: string) => void;
};
const TimetableDay: React.FC<Props> = ({ preview, day, data, selectedClasses, select }) => {
  const { t } = useTranslation();
  const container = tw("flex-col", "w-full", "pt-6", {
    "sm:w-2/3": !preview,
    "md:w-1/2": !preview,
  });
  const dayTitleContainer = tw(
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "border-b-2",
    "rounded-full",
    "mx-4",
    "mb-4",
    "border-orange"
  );
  return (
    <div id={day} className={container}>
      <div className={dayTitleContainer}>
        <h1 className="text-4xl text-white mb-2">{t(day)}</h1>
      </div>
      {data.length === 0 ? (
        <p className="text-white text-3xl text-center mb-auto mt-auto">{t("noData")}</p>
      ) : (
        data.map((elem, index) => (
          <TimetableClass
            elem={elem}
            key={index.toString() + elem.uuid}
            selected={selectedClasses.has(elem.uuid)}
            select={select}
          />
        ))
      )}
    </div>
  );
};

export default TimetableDay;
