import * as React from "react";
import { LocalizedRouter as Router } from "gatsby-theme-i18n";
import firebase from "gatsby-plugin-firebase";
import { useTranslation } from "react-i18next";
import Timetable from "../../components/client-only/timetable";
import Settings from "../../components/client-only/settings";
import Manage from "../../components/client-only/manage";
import New from "../../components/client-only/new";
import Account from "../../components/client-only/account";
import useStaticAuthState from "../../hooks/staticAuthState";
import Seo from "../../components/seo";
import NotFoundPage from "../404";
import Personal from "../../components/client-only/personal";
import { useLocalizedHelpers } from "../../hooks/useLocalizedHelpers";
import StandardToaster from "../../components/standardToaster";

export default function App({ location }) {
  const { navigate } = useLocalizedHelpers();
  const [user] = useStaticAuthState(firebase.auth);
  const { t } = useTranslation();
  if (user == null) {
    // If we’re not logged in, redirect to the home page.
    if (typeof window !== "undefined") navigate(`/login?redirect=${location.pathname}`, { replace: true });
    return (
      <div className="flex justify-center items-center flex-grow h-screen">
        <h1 className="text-white text-center text-4xl md:text-5xl lg:text-7xl">{t("notAuthenticated")}</h1>
      </div>
    );
  }
  return (
    <>
      <Seo />
      <StandardToaster />
      <Router className="h-full" basePath="/app">
        <NotFoundPage default />
        <Timetable path="/timetable" />
        <Settings path="/settings" />
        <Manage path="/edit" />
        <Personal path="/personal" />
        <New path="/new" />
        <Account path="/account" />
      </Router>
    </>
  );
}
