import * as React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { MdDone } from "react-icons/md";
import Fade from "react-reveal/Fade";
import firebase from "gatsby-plugin-firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useLocalizedHelpers } from "../../../hooks/useLocalizedHelpers";
import DisplayTimetable from "../../elements/displayTimetable";
import { TagId } from "../../../util/enpoints/types";
import SelectClassTimetable from "./selectClassTimetable";

type Props = {
  tagId: TagId;
};
const TimetableSelect: React.FC<Props> = ({ tagId }) => {
  const { t } = useTranslation(["translation", "timetable"]);
  const [user] = useAuthState(firebase.auth());
  const docRef = useMemo(() => firebase.firestore().doc(`user-data/${user.uid}`), [user.uid]);
  const [value] = useDocumentData(docRef);
  const { navigate } = useLocalizedHelpers();
  const selectionUIDs = useMemo(() => [tagId], [tagId]);
  const [enabled, setEnabled] = useState(false);
  const selectClassRef = useRef();

  const selectCurrent = useCallback(async () => {
    let newSelections: Array<string> = [];
    if (!enabled) {
      newSelections = [tagId];
    } else {
      newSelections = Array.from(selectClassRef?.current?.getSelections());
    }
    if (value?.selections == null) await docRef.set({ selections: newSelections }, { merge: true });
    else {
      const uniqueSelections = new Set([...value.selections, ...newSelections]);
      await docRef.set({ selections: Array.from(uniqueSelections) }, { merge: true });
    }
    navigate(-1);
  }, [enabled, value?.selections, docRef, tagId, navigate]);

  return (
    <div className="h-full flex flex-col justify-center items-center">
      <div className="flex flex-row text-white justify-center items-center gap-4">
        <p className="text-xl">{t("timetable:selectClasses")}</p>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${enabled ? "bg-orange" : "bg-gray-300"}
          relative inline-flex items-center flex-shrink-0 h-6 w-16 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 select-none`}
        >
          <span
            aria-hidden="true"
            className={`${enabled ? "translate-x-10" : "translate-x-0"}
            pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
      </div>
      {enabled ? (
        <SelectClassTimetable preview selectionUIDs={selectionUIDs} ref={selectClassRef} />
      ) : (
        <DisplayTimetable preview selectionUIDs={selectionUIDs} />
      )}

      <Fade right>
        <button
          type="button"
          className="border-2 border-blue-100  group fixed right-10 bottom-28 flex justify-center items-center text-white text-2xl cursor-pointer h-10 px-2 py-2 rounded-full
                        transition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-green px-4 h-14 w-14"
          onClick={selectCurrent}
        >
          <MdDone className="transition duration-500 ease-in-out transform group-hover:scale-125" />
        </button>
      </Fade>
    </div>
  );
};
export default TimetableSelect;
