import * as React from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { MdAccountCircle, MdDateRange, MdEdit, MdInfo, MdViewList } from "react-icons/md";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import NavBar from "../navBar";
import ZoomTransition from "../transitions/zoomTransition";

export default function Settings() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full">
      <Helmet title={t("settings")} defer={false} />
      <NavBar title={t("settings")} navButton={{ icon: MdDateRange, location: "/app/timetable" }} />
      <div className="flex h-full justify-center flex-col items-center">
        <div className="flex flex-col h-full justify-center space-y-6">
          <ZoomTransition initial>
            <Link
              to="/app/personal"
              className="flex justify-start items-center text-white text-xl cursor-pointer h-10 px-2 py-2 rounded-2xl
                        ZoomTransition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-gray-300 px-4"
            >
              <MdViewList />
              <p>{t("personalTimetable")}</p>
            </Link>
          </ZoomTransition>
          <ZoomTransition initial>
            <Link
              to="/app/edit"
              className="flex justify-start items-center text-white text-xl cursor-pointer h-10 px-2 py-2 rounded-2xl
                        ZoomTransition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-gray-300 px-4"
            >
              <MdEdit />
              <p>{t("editTimetables")}</p>
            </Link>
          </ZoomTransition>
          <ZoomTransition initial>
            <Link
              to="/app/account"
              className="flex justify-start items-center text-white text-xl cursor-pointer h-10 px-2 py-2 rounded-2xl
                        ZoomTransition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-gray-300 px-4 w-full"
            >
              <MdAccountCircle />
              <p>{t("account")}</p>
            </Link>
          </ZoomTransition>
          <ZoomTransition initial>
            <Link
              to="/info"
              className="flex justify-start items-center text-white text-xl cursor-pointer h-10 px-2 py-2 rounded-2xl
                        ZoomTransition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-gray-300 px-4 w-full"
            >
              <MdInfo />
              <p>{t("info")}</p>
            </Link>
          </ZoomTransition>
        </div>
      </div>
    </div>
  );
}
