import * as React from "react";
import { Transition } from "@headlessui/react";
import InitialTransition from "./initialTransition";

const ZoomTransition: React.FC<{ initial?: boolean } & React.ComponentPropsWithoutRef<typeof Transition>> = ({
  children,
  initial = false,
  ...rest
}) => {
  if (initial) {
    return (
      <InitialTransition
        enter="InitialTransition transform ease-in-out duration-500"
        enterFrom="opacity-0 scale-0"
        enterTo="opacity-100 scale-100"
        leave="InitialTransition ease-in duration-500"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-0"
        {...rest}
      >
        {children}
      </InitialTransition>
    );
  }

  return <Transition {...rest}>{children}</Transition>;
};

export default ZoomTransition;
