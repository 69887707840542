import * as React from "react";
import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

const InitialTransition: React.FC<React.ComponentPropsWithoutRef<typeof Transition>> = ({ children, ...rest }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <Transition show={show} appear {...rest}>
      {children}
    </Transition>
  );
};

export default InitialTransition;
