import * as React from "react";
import firebase from "gatsby-plugin-firebase";
import { MdAccountBox, MdEmail, MdPerson, MdSecurity, MdSettings } from "react-icons/md";
import { useAuthState } from "react-firebase-hooks/auth";
import { IoMdLogOut } from "react-icons/io";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { useUserInfo } from "../../hooks/userInfo";
import NavBar from "../navBar";
import { useLocalizedHelpers } from "../../hooks/useLocalizedHelpers";

export default function Account() {
  const [user] = useAuthState(firebase.auth());
  const userInfo = useUserInfo(user);
  const { t } = useTranslation(["translation", "applyInstitution"]);
  const { navigate } = useLocalizedHelpers();

  async function signOut() {
    await firebase.auth().signOut();
    await navigate("/");
  }

  return (
    <div className="flex flex-col h-full">
      <Helmet title={t("yourAccount")} defer={false} />
      <NavBar title={t("yourAccount")} navButton={{ icon: MdSettings, location: "/app/settings" }} />
      <div className="flex flex-col h-full justify-center">
        <div className="flex flex-col h-2/3 justify-evenly items-center">
          {user?.photoURL == null ? (
            <p className="text-white text-7xl text-center pb-1">
              <MdAccountBox />
            </p>
          ) : (
            <img className="h-50 w-50" src={user?.photoURL} alt="userPhoto" />
          )}
          <div className="flex justify-center items-start flex-col">
            <div className="flex items-center justify-center space-x-2 ">
              <p className="text-white text-2xl text-center pb-1">
                <MdSecurity />
              </p>
              <p className="text-white text-2xl text-center">{userInfo?.isOwner ? t("admin") : t("user")}</p>
            </div>
            <div className="flex items-center justify-center space-x-2 ">
              <p className="text-white text-2xl text-center pb-1">
                <MdPerson />
              </p>
              <p className="text-white text-2xl text-center">{user?.displayName}</p>
            </div>
            <div className="flex items-center justify-center space-x-2 ">
              <p className="text-white text-2xl text-center pb-1">
                <MdEmail />
              </p>
              <p className="text-white text-2xl text-center">{user?.email}</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-4">
            <Link
              to="/apply-institution"
              className="cursor-pointer w-1/2 bg-orange text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 px-4 rounded-lg"
            >
              {t("applyInstitution:goToApplyInstitution")}
            </Link>
            <button
              type="button"
              onClick={signOut}
              className="flex bg-gray-300 px-2 py-1 rounded-2xl
                        cursor-pointer transition duration-500 ease-in-out transform hover:scale-110 items-center justify-center space-x-2 "
            >
              <p className="text-white text-2xl text-center pb-1">
                <IoMdLogOut />
              </p>
              <p className="text-white text-2xl text-center">{t("logOut")}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
