import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { MdClass, MdDateRange, MdDelete, MdErrorOutline, MdSync } from "react-icons/md";
import { toast } from "react-hot-toast";
import { useLocalization } from "gatsby-theme-i18n";
import { useTranslation } from "react-i18next";
import { getShorthands } from "../../util/enpoints";
import ZoomTransition from "../transitions/zoomTransition";
import { Tag } from "../../util/enpoints/types";

type Props = {
  uid: string;
  onDelete: (uid: string) => void;
};

const EditElement: React.FC<Props> = ({ uid, onDelete }) => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [parent, setParent] = useState("");
  const [shorthands, setShorthands] = useState<Tag[]>([]);
  const [icon, setIcon] = useState(<MdDateRange />);
  const { locale } = useLocalization();
  const { t } = useTranslation(["translation", "timetable"]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let cleanup = false;

    async function getData(): Promise<void> {
      try {
        setLoading(true);
        const response = await getShorthands(uid, locale, cancelToken.token);
        if (cleanup) {
          return;
        }
        if (response.data.class == null) {
          const sh = [...response.data.shorthands];
          sh.pop();
          setShorthands(sh);
          setName(response.data.shorthands[response.data.shorthands.length - 1].name);
          setParent("");
          setIcon(<MdDateRange />);
        } else {
          const sh = [...response.data.shorthands];
          sh.pop();
          setShorthands(sh);
          setName(`${response.data.class.subject} - ${response.data.class.teacher}`);
          setParent(
            `${t(response.data.class.day)} - ${t("timetable:week")} ${response.data.class.week} - ${
              response.data.class.tagName
            }`
          );
          setIcon(<MdClass />);
        }
        setLoading(false);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response.status === 404) {
            setName(t("folderNotFound"));
            setParent(t("folderNotFound"));
            setIcon(<MdErrorOutline />);
            setLoading(false);
          } else
            for (const errMsg of e.response.data.errors) {
              toast.error(errMsg);
            }
        } else if (axios.isCancel(e)) {
          // no-op canceled
        } else {
          throw e;
        }
      }
    }

    getData();
    return () => {
      cleanup = true;
      cancelToken.cancel("Only one request can be performed");
    };
  }, [locale, t, uid]);
  return (
    <ZoomTransition initial>
      <div className="items-center">
        <div className="flex justify-center items-center bg-gray-300 mx-4 py-2 min-h-32 rounded-2xl">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <h1 className="animate-spin h-12 w-12 text-white text-center text-5xl">
                <MdSync />
              </h1>
            </div>
          ) : (
            <div className="flex justify-center items-center h-full w-full">
              <div className="flex justify-center w-1/4 h-full items-center ">
                <p className=" text-white text-4xl">{icon}</p>
              </div>
              <div className="flex flex-col w-2/4 space-y-2">
                <p className="text-white text-xl text-extrabold">{name}</p>
                {parent != "" ? <p className="text-white text-lg text-bold">{parent}</p> : null}
                <div className="flex flex-wrap gap-1 text-white text-sm">
                  {shorthands
                    .reverse()
                    .map((el) => el.name)
                    .join(" - ")}
                </div>
              </div>
              <div className="flex justify-center h-full w-1/4 items-center">
                <button
                  type="button"
                  className="cursor-pointer text-white transition duration-500 ease-in-out hover:text-red hover:animate-pulse text-7xl border-l-2 border-orange"
                  aria-label="Delete"
                  onClick={() => onDelete(uid)}
                >
                  <MdDelete />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </ZoomTransition>
  );
};
export default EditElement;
