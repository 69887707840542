import * as React from "react";
import { useMemo } from "react";
import { MdLibraryAdd, MdSettings } from "react-icons/md";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "gatsby-plugin-firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import NavBar from "../navBar";
import EditElement from "../elements/editElement";

export default function Manage() {
  const [user] = useAuthState(firebase.auth());
  const docRef = useMemo(() => firebase.firestore().doc(`user-data/${user.uid}`), [user.uid]);
  const [value] = useDocumentData(docRef);
  const { t } = useTranslation();

  const onDelete = (uid) => {
    const selections = value.selections.filter((elem) => elem !== uid);
    docRef.set({ selections }, { merge: true });
  };

  return (
    <div className="flex flex-col h-full">
      <Helmet title={t("editTimetables")} defer={false} />
      <NavBar title={t("editTimetables")} navButton={{ icon: MdSettings, location: "/app/settings" }} />
      <div className="flex justify-center flex-col space-y-4 items-center">
        <div className="flex-col w-full sm:w-2/3 lg:w-1/3 mx-2 space-y-4 mt-4 pb-36 sm:pb-2">
          {value != null && value?.selections?.length !== 0 ? (
            value?.selections?.map((elem) => <EditElement onDelete={onDelete} uid={elem} key={elem} />)
          ) : (
            <h1 className="text-white text-center w-3/4 mx-auto text-xl md:text-2xl self-center">
              {t("noTimetableSelected")}
            </h1>
          )}
        </div>
      </div>
      <div className="flex h-full justify-center flex-col items-center">
        <Link
          to="/app/new"
          className="fixed right-10 bottom-10 flex justify-center items-center text-white text-2xl cursor-pointer h-10 px-2 py-2 rounded-full
                        transition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-orange px-4 h-14 w-14"
        >
          <MdLibraryAdd />
        </Link>
      </div>
    </div>
  );
}
