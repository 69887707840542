import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { MdArrowBack, MdSettings, MdSync } from "react-icons/md";
import axios, { CancelToken } from "axios";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocalization } from "gatsby-theme-i18n";
import { useEffectOnce, useLocalStorage } from "react-use";
import Fade from "react-reveal/Fade";
import { getChildrenTags, getShorthands } from "../../util/enpoints";
import NewElement from "../elements/newElement";
import NavBar from "../navBar";
import { Tag, TagId } from "../../util/enpoints/types";
import TimetableSelect from "./new-page/timetableSelection";
import Breadcrumbs from "../breadcrumbs";

type GetNext = (tag: Tag) => Promise<void>;
type GoBack = (forcePrevTags?: Tag[]) => Promise<void>;

const New: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useLocalization();
  const [latestLocation, setLatestLocation] = useLocalStorage<Tag | "" | null>("new-latest-location", "");

  const [previousTags, setPreviousTags] = useState<Array<Tag | null>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentTag, setCurrentTag] = useState<Tag | null>(null);
  const [data, setData] = useState<Tag[]>(null);

  const goBack = useCallback<GoBack>(
    async (forcePrevTags = null) => {
      const prevTags = forcePrevTags || previousTags;
      const tag = prevTags[prevTags.length - 1];
      setPreviousTags((prev) => {
        const arr = [...prev];
        arr.pop();
        return arr;
      });
      setLoading(true);
      const cancelToken = axios.CancelToken.source();
      const response = await getChildrenTags(tag != null ? tag.uuid : "", locale, cancelToken.token);
      setData(response.data);
      setCurrentTag(tag);
      setLatestLocation(tag);
      setLoading(false);
    },
    [locale, previousTags, setLatestLocation]
  );

  const getNext = useCallback<GetNext>(
    async (tag) => {
      setLoading(true);
      const cancelToken = axios.CancelToken.source();
      const response = await getChildrenTags(tag.uuid, locale, cancelToken.token);
      setData(response.data);
      setPreviousTags((prev) => [...prev, currentTag]);
      setCurrentTag(tag);
      setLatestLocation(tag);
      setLoading(false);
    },
    [currentTag, locale, setLatestLocation]
  );

  const moveToLocation = useCallback(
    async (tagUuid?: TagId, cancelToken?: CancelToken): Promise<boolean> => {
      try {
        setLoading(true);
        let arr = [];
        if (tagUuid != null) {
          const response = await getShorthands(tagUuid, locale, cancelToken);
          arr = response.data.shorthands;
        }
        arr.splice(0, 0, null);
        setPreviousTags(arr);
        await goBack(arr);
        setLoading(false);
        return true;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response.status === 404) {
            toast.error(t("restorePointNotFound"));
            return false;
          }
          for (const errMsg of e.response.data.errors) {
            toast.error(errMsg);
          }
          return false;
        }
        throw e;
      }
    },
    [goBack, locale, t]
  );

  useEffectOnce(() => {
    const cancelToken = axios.CancelToken.source();
    let cleanup = false;

    async function getData(): Promise<void> {
      try {
        setLoading(true);
        const response = await getChildrenTags("", locale, cancelToken.token);
        if (cleanup) {
          return;
        }
        setData(response.data);
        setLoading(false);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          for (const errMsg of e.response.data.errors) {
            toast.error(errMsg);
          }
        } else {
          throw e;
        }
      }
    }

    console.log(latestLocation);
    if (latestLocation != null && latestLocation !== "") {
      moveToLocation(latestLocation.uuid, cancelToken.token)
        .then((res) => (res ? null : getData()))
        .catch((e) => toast.error(e.message));
    } else {
      getData();
    }

    return () => {
      cleanup = true;
      cancelToken.cancel("Only one request can be performed");
    };
  });

  const centerDisplay = useMemo(() => {
    if (loading)
      return (
        <div className="flex justify-center items-center h-full">
          <h1 className="animate-spin  text-white text-5xl">
            <MdSync />
          </h1>
        </div>
      );
    if (data.length !== 0) {
      return (
        <div className="flex flex-col flex-grow h-auto min-h-min-content justify-center items-center">
          <div className="flex flex-wrap flex-shrink justify-center lg:items-center m-auto">
            {data.map((elem, index) => (
              <NewElement key={index.toString()} onClick={() => getNext(elem)} text={elem.name} />
            ))}
          </div>
        </div>
      );
    }
    return <TimetableSelect tagId={currentTag.uuid} />;
  }, [currentTag?.uuid, data, getNext, loading]);

  const breadcrumbs = useMemo(
    () =>
      previousTags.map((tag) => {
        if (tag == null) {
          return { text: t("home"), url: () => moveToLocation(null) };
        }
        return { text: tag.name, url: () => moveToLocation(tag.uuid) };
      }),
    [moveToLocation, previousTags, t]
  );

  return (
    <div className="flex flex-col h-full">
      <Helmet title={t("newTimetable")} defer={false} />
      <NavBar title={t("newTimetable")} navButton={{ icon: MdSettings, location: "/app/settings" }} />
      <Breadcrumbs history={breadcrumbs} />
      <div className="overflow-y-scroll h-full flex flex-col">{centerDisplay}</div>
      <Fade right when={previousTags.length > 0}>
        <button
          type="button"
          className="border-2 border-blue-100 group fixed right-10 bottom-10 flex justify-center items-center text-white text-2xl cursor-pointer h-10 px-2 py-2 rounded-full
                        transition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-orange px-4 h-14 w-14"
          onClick={() => goBack()}
        >
          <MdArrowBack className="transition duration-500 ease-in-out transform group-hover:scale-125" />
        </button>
      </Fade>
    </div>
  );
};

export default New;
