import * as React from "react";
import { useEffect, useMemo } from "react";
import firebase from "gatsby-plugin-firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { MdSettings } from "react-icons/md";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DisplayTimetable from "../elements/displayTimetable";
import NavBar from "../navBar";
import { useLocalizedHelpers } from "../../hooks/useLocalizedHelpers";

export default function Timetable() {
  const { navigate } = useLocalizedHelpers();
  const [user] = useAuthState(firebase.auth());
  const docRef = useMemo(() => firebase.firestore().doc(`user-data/${user.uid}`), [user.uid]);
  const docRefPersonal = useMemo(
    () => firebase.firestore().doc(`user-data/${user.uid}/data/personalTimetable/`),
    [user.uid]
  );
  const [value, loading] = useDocumentDataOnce(docRef);
  const [pValue] = useDocumentDataOnce(docRefPersonal);
  const { t } = useTranslation("timetable");
  useEffect(() => {
    async function doNavigation() {
      if (loading) return;
      if (value == null) {
        await navigate("/app/edit");
        return;
      }
      if (value.selections == null || value.selections.length === 0) {
        await navigate("/app/edit");
      }
    }

    doNavigation();
  }, [value, loading, navigate]);
  return (
    <div className="flex flex-col h-full">
      <Helmet title={t("title")} defer={false} />
      <NavBar title={t("title")} navButton={{ icon: MdSettings, location: "/app/settings" }} />
      <DisplayTimetable
        preview={false}
        selectionUIDs={value?.selections}
        externalTimetable={pValue?.timetable}
        externalTagName={t("translation:personalTimetable")}
      />
    </div>
  );
}
