import * as React from "react";
import { useMemo } from "react";
import { MdSchool } from "react-icons/md";
import { IoMdClock } from "react-icons/io";
import { Transition } from "@headlessui/react";
import { TClass } from "../../../../util/enpoints/types";

type Props = {
  elem: TClass;
  selected: boolean;
  select: (uuid: string) => void;
};
const TimetableClass: React.FC<Props> = ({ elem, selected, select }) => {
  const color = useMemo(() => {
    if (selected === false) {
      return "bg-gray-400";
    }
    try {
      let [word] = elem.type.split(",");
      word = word.trim().toLowerCase();
      switch (word) {
        case "curs":
          return "bg-red";
        case "seminar":
          return "bg-green";
        case "laborator":
          return "bg-instagram";
        default:
          return "bg-orange";
      }
    } catch (e) {
      console.error(e);
    }
    return "bg-orange";
  }, [elem.type, selected]);
  return (
    <Transition
      show
      appear
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-150"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      className="mx-4"
    >
      <button
        type="button"
        className={`flex flex-col w-full flex-grow justify-between rounded-2xl h-50 my-2 px-4 ${color}`}
        onClick={() => select(elem.uuid)}
      >
        <div className="flex flex-row flex-nowrap justify-between items-center w-full">
          <div className="flex-shrink text-white text-2xl text-center text-bold pb-1.5">
            <MdSchool />
          </div>
          <p className="flex-grow text-white text-2xl md:text-3xl text-center text-bold">{elem.subject}</p>
          <div className="flex-shrink text-white text-2xl text-center text-bold pb-1.5 opacity-0">
            <MdSchool />
          </div>
        </div>
        <div className="flex justify-center flex-col items-center w-full">
          <p className="text-white text-lg text-center text-bold">{elem.teacher}</p>
        </div>
        <div className="flex justify-center flex-col items-center w-full">
          <p className="text-white text-lg text-center text-bold">{elem.type}</p>
        </div>
        <div className="flex justify-between items-center space-x-8 w-full">
          <div className="flex justify-center items-center">
            <p className="text-white text-2xl text-center text-bold pb-1.5">
              <IoMdClock />
            </p>
            <p className="text-white text-md text-center text-bold ml-1">
              {new Date(elem.start * 1000).toISOString().substr(11, 5)}
            </p>
          </div>
          <p className="text-white text-lg text-center text-bold">{elem.tagName}</p>
          <div className="flex justify-center items-center">
            <p className="text-white text-md text-center text-bold mr-1">
              {new Date((elem.start + elem.duration) * 1000).toISOString().substr(11, 5)}
            </p>
            <p className="text-white text-2xl text-center text-bold pb-1.5">
              <IoMdClock />
            </p>
          </div>
        </div>
      </button>
    </Transition>
  );
};

export default TimetableClass;
