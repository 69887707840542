import * as React from "react";
import { useCallback, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { MdSettings } from "react-icons/md";
import { useTranslation } from "react-i18next";
import NavBar from "../navBar";
import EditTimetable from "../elements/editTimetable";
import useTimetable from "../../hooks/useTimetable";

const Personal: React.FC = () => {
  const [timeTable, dispatchTimeTable, handleUndo, handleRedo, canUndo, canRedo] = useTimetable();
  const { t } = useTranslation(["translation", "timetable"]);

  const loadDraft = useCallback(() => {
    async function getData(): Promise<void> {
      const user = firebase.auth().currentUser;
      const data = (await firebase.firestore().doc(`user-data/${user.uid}/data/personalTimetable/`).get()).data();
      if (data == null || data?.timetable == null) {
        dispatchTimeTable({ type: "reset" });
      } else {
        dispatchTimeTable({ type: "set", timeTable: data.timetable });
      }
    }

    const dataPromise = getData();
    toast.promise(dataPromise, {
      loading: t("timetable:loadingPTimetableInProgress"),
      success: t("timetable:loadingTimetableSuccess"),
      error: t("timetable:loadingTimetableError"),
    });
  }, [dispatchTimeTable, t]);

  const saveDraft = useCallback(() => {
    async function setData(): Promise<void> {
      const user = firebase.auth().currentUser;
      const doc = firebase.firestore().doc(`user-data/${user.uid}/data/personalTimetable/`);
      console.log("saving", timeTable);
      await doc.set({ timetable: timeTable }, { merge: true });
    }

    const dataPromise = setData();

    toast.promise(dataPromise, {
      loading: t("timetable:savingTimetableInProgress"),
      success: t("timetable:savingTimetableSuccess"),
      error: t("timetable:savingTimetableError"),
    });
  }, [t, timeTable]);

  useEffect(() => {
    loadDraft();
  }, [loadDraft]);

  return (
    <div className="flex flex-col h-full">
      <Helmet title={t("personalTimetable")} defer={false} />
      <NavBar title={t("personalTimetable")} navButton={{ icon: MdSettings, location: "/app/settings" }} />
      <div className="flex flex-col mx-4 md:mx-20">
        <EditTimetable
          timeTable={timeTable}
          dispatchTimeTable={dispatchTimeTable}
          handleUndo={handleUndo}
          handleRedo={handleRedo}
          canUndo={canUndo}
          canRedo={canRedo}
          title={t("personalTimetable")}
          alternativeStyle
        />
      </div>
      <div className="flex flex-row h-28 w-full justify-center space-x-4 items-center">
        <button
          type="button"
          className="transition duration-500 ease-in-out text-white cursor-pointer text-xl border-b-2 hover:border-green rounded-full px-6"
          onClick={saveDraft}
        >
          {t("save")}
        </button>
      </div>
    </div>
  );
};

export default Personal;
