import * as React from "react";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocalization } from "gatsby-theme-i18n";
import { useSet } from "react-use";
import { Days, TagId, TClassSimple, TimetableSimple } from "../../../../util/enpoints/types";
import { getTimetable } from "../../../../util/enpoints";
import { createUUID } from "../../../../util/uuid";
import TimetableDay from "./timetableDay";

type Props = {
  selectionUIDs: TagId[];
  preview: boolean;
};

type Ref = {
  getSelections: () => Set<string>;
};

const SelectClassTimetable = React.forwardRef<Ref, Props>(({ selectionUIDs, preview }, ref) => {
  const [data, setData] = useState([]);
  const [selectionTimetable, setSelectionTimetable] = useState<TimetableSimple<TClassSimple>>(null);
  const [week, setWeek] = useState(0);
  const { t } = useTranslation(["translation", "timetable"]);
  const { locale } = useLocalization();
  const [selectedClasses, { toggle }] = useSet<string>();

  useImperativeHandle(
    ref,
    () => ({
      getSelections: () => selectedClasses,
    }),
    [selectedClasses]
  );

  const selectClass = useCallback(
    (uuid: string) => {
      toggle(uuid);
    },
    [toggle]
  );

  useEffect(() => {
    if (selectionTimetable != null) {
      setData(selectionTimetable.weeks);
    }
  }, [selectionTimetable]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let cleanup = false;
    const toastId = createUUID();

    async function getWeeks(UIDs): Promise<void> {
      try {
        if (UIDs == null || UIDs.length === 0) return;

        const response = await getTimetable(UIDs, locale, cancelToken.token);
        if (cleanup) {
          return;
        }
        setSelectionTimetable(response.data);
      } catch (e) {
        if (e instanceof axios.Cancel) {
          toast.dismiss(toastId);
          console.log(e);
          return;
        }
        if (axios.isAxiosError(e)) {
          for (const errMsg of e.response.data.errors) {
            toast.error(errMsg);
          }
        } else {
          throw e;
        }
      }
    }

    toast.promise(
      getWeeks(selectionUIDs),
      {
        loading: t("timetable:loadingTimetableInProgress"),
        success: t("timetable:loadingTimetableSuccess"),
        error: t("timetable:loadingTimetableError"),
      },
      { id: toastId }
    );
    return () => {
      cleanup = true;
      cancelToken.cancel("Only one request can be performed");
      toast.dismiss(toastId);
    };
  }, [locale, selectionUIDs, t]);

  return (
    <>
      {data.length > 1 ? (
        <div className="flex flex-nowrap m-auto max-w-full h-16 overflow-x-auto overflow-y-hidden">
          <div className="flex flex-nowrap h-full justify-center items-center px-4">
            <button
              type="button"
              onClick={() => setWeek(0)}
              className={`cursor-pointer transform hover:scale-105 text-center ${
                week === 0 ? "bg-orange" : "bg-transparent"
              }
                transition duration-200 ease-in-out hover:bg-gray-400 text-white min-w-20 px-5 py-2 rounded-l-full border-2 border-orange `}
            >
              {t("timetable:week")} 0
            </button>
            <button
              type="button"
              onClick={() => setWeek(1)}
              className={`cursor-pointer transform hover:scale-105 text-center ${
                week === 1 ? "bg-orange" : "bg-transparent"
              }
                transition duration-200 ease-in-out hover:bg-gray-400 text-white min-w-20 px-5 py-2 rounded-r-full border-2 border-orange `}
            >
              {t("timetable:week")} 1
            </button>
          </div>
        </div>
      ) : null}
      <div className="overflow-y-scroll h-full">
        <div className="flex flex-col items-center justify-center">
          {data.length !== 0 ? (
            Days.map((value) => (
              <TimetableDay
                key={value}
                day={value}
                preview={preview}
                data={[...data[week][value]]}
                selectedClasses={selectedClasses}
                select={selectClass}
              />
            ))
          ) : (
            <p className="text-white text-3xl text-center mb-auto mt-auto">{t("noData")}</p>
          )}
        </div>
      </div>
    </>
  );
});
SelectClassTimetable.displayName = "SelectClassTimetable";
export default SelectClassTimetable;
